html,
body,
#__next {
    height: 100%;
    width: 100%;
    margin: 0;
    padding-top: env(safe-area-inset-top);
    padding-right: env(safe-area-inset-right);
    padding-left: env(safe-area-inset-left);
}

* {
    box-sizing: border-box;
}

@media screen and (display-mode: standalone) {
    @supports (-webkit-touch-callout: none) {
        html,
        body,
        #__next {
            padding-bottom: 1rem;
            overscroll-behavior: contain;
            scroll-behavior: smooth;
            touch-action: manipulation;
        }
        input, textarea, select, button {
            font-size: 16px;
        }
    }
}
