.card {
    perspective: 1000px;
    cursor: pointer;
}

.hide-cursor {
    cursor: default;
}

.card-inner {
    width: 100%;
    height: 100%;
    position: relative;
    transform-style: preserve-3d;
    transition: transform 0.6s;
}

.card.flipped .card-inner {
    transform: rotateY(180deg);
}

.card-front,
.card-back {
    width: 100%;
    height: 100%;
    position: absolute;
    backface-visibility: hidden;
}

.card-front {
    color: black;
}

.card-back {
    background-color: transparent;
    color: black;
    transform: rotateY(180deg);
}

.hidden {
    visibility: hidden;
}
